import _ from 'lodash';

// /**
//  * @class JsonTree
//  * Input is in following format:
//  * {
//  *  "name": "person name",
//  *  "parent_uid": "2",
//  * }
//  **/

class MultiFamilyTreeData {
    constructor(data) {
        this.data = JSON.parse(JSON.stringify(data)); // Deep copy to avoid modifying original data
        this.rootIds = [];
        this.preProcessData();
    }

    preProcessData() {
        this.populateChildren();
        this.identifyRootNodes();
    }

    populateChildren() {
        // Initialize children array in each node
        Object.keys(this.data).forEach(uid => {
            this.data[uid].children = [];
        });

        // Populate children
        Object.entries(this.data).forEach(([uid, node]) => {
            if (node.parent_uid && this.data[node.parent_uid]) {
                this.data[node.parent_uid].children.push(uid);
            }
        });
    }

    identifyRootNodes() {
        this.rootIds = Object.keys(this.data).filter(uid => !this.data[uid].parent_uid);
        this.rootIds = this.sortNodes(this.rootIds);
    }

    updateTree(newData) {
        Object.entries(newData).forEach(([uid, node]) => {
            if (this.data[uid]) {
                Object.assign(this.data[uid], node);
            } else {
                this.data[uid] = node;
            }
        });
        this.preProcessData();
    }

    getNode(uid) {
        return this.data[uid] || null;
    }

    getHusband(uid) {
        return _.find(this.data, (node) => node.spouse?.uid === uid);
    }

    getNodes() {
        return this.data;
    }

    getParent(uid) {
        const node = this.getNode(uid);
        return node ? this.getNode(node.parent_uid) : null;
    }

    getChildren(uid) {
        const node = this.getNode(uid);
        return node ? node.children.map(childUid => this.getNode(childUid)) : [];
    }

    getRootNodes() {
        return this.roots.map(uid => this.getNode(uid));
    }

    sortNodes(nodes) {
        let rootNodes = _.map(nodes, rootId => this.getNode(rootId));
        let sortedRootNodes = _.orderBy(rootNodes,  ["gautra", "subcaste", "father_name", "name"]);
        return _.map(sortedRootNodes, rootNode => rootNode.graph_node_id);
    }

    // Convert data into format required by react-native-tree-multi-select
    transformToTreeData() {
        const formatNode = (uid, level) => {
            const node = this.getNode(uid);
            return {
                id: uid,
                // name: node.name,
                level: level,
                ...node, // Include all other node data
                children: node?.children.map(formatNode, level+1), // Recursively format children
            };
        };

        return this.rootIds.map(formatNode, 0);
    }
};

export default MultiFamilyTreeData;
