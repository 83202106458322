import React, { useState, useEffect, useRef } from 'react';
import { Tree } from 'react-arborist';
import { useLocation } from '@reach/router';
import MultiFamilyTreeData from '../../json_tree_lib/JsonTree';
import { getMukhiyas } from '../../actions/mukhiyasAction';
import { getFamilyInfo } from '../../actions/familyTreeAction';
import NodeComponent from './NodeComponent';
import { CircularProgress } from '@mui/material';
import LoadingSpinner from '../loader';

const MukhiyasListTreeView = () => {
  const treeData = useRef(new MultiFamilyTreeData({}));
  const [graphData, setGraphData] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [loadingNode, setLoadingNode] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const params = new URLSearchParams(decodeURI(location.search));
  const samajMitraId = params.get('volunteerId');

  useEffect(() => {
    if (samajMitraId) fetchMukhiyasList(samajMitraId);
  }, [samajMitraId]);

  const fetchFamilyData = async (personId) => {
    console.log('Fetching Family Data for:', personId);
    try {
      setLoadingNode(personId);
      const response = await getFamilyInfo(personId, 'json_tree_mongo');
      if (response.result) {
        treeData.current.updateTree(response.result);
        setGraphData([...treeData.current.transformToTreeData()]);
        setExpandedNodes((prev) => new Set([...prev, personId])); // Ensure unique IDs
      }
    } catch (error) {
      console.error('Error fetching family data:', error);
      // Alert.alert("Error", "Failed to fetch family data.");
    } finally {
      setLoadingNode(null);
    }
  };

  const fetchMukhiyasList = async (graphNodeId) => {
    setLoading(true);
    try {
      setLoadingNode(graphNodeId);
      const newData = await getMukhiyas(graphNodeId);

      if (newData?.data?.linked_mukhiyas) {
        const transformedMukhiyas = newData.data.linked_mukhiyas.reduce((acc, mukhiya) => {
          acc[mukhiya.graph_node_id] = { ...mukhiya, uid: mukhiya.graph_node_id }; // Use UID as the key, keep all other data unchanged
          return acc;
        }, {});
        console.log('Transformed Mukhiyas:', transformedMukhiyas);
        treeData.current.updateTree(transformedMukhiyas);
      }

      // Convert tree structure to usable graph data
      const transformedData = treeData.current.transformToTreeData();
      setGraphData([...transformedData]);
    } catch (error) {
      console.error('Error fetching family data:', error);
    } finally {
      setLoadingNode(null);
      setLoading(false);
    }
  };

  const handleNodeClick = async (node) => {
    console.log('Clicked node:', node);
    if (!expandedNodes.has(node.id)) {
      await fetchFamilyData(node.id);
      setExpandedNodes((prev) => new Set([...prev, node.id])); // ✅ Properly update state
      node.toggle();
    } else {
      // setExpandedNodes((prev) => {
      //     const newSet = new Set(prev);
      //     newSet.delete(node.id);
      //     return newSet;
      // });
    }
    node.toggle();
  };
  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <LoadingSpinner message={'Loading mukhiyas...'}></LoadingSpinner>
      </div>
    );
  }

  if (!loading && graphData.length === 0) {
    return (
      <div style={{ textAlign: 'center', padding: '20px', fontSize: '20px', color: 'black' }}>
        <p>अभी तक मुखिया नही बने है </p>
      </div>
    );
  }

  return (
    <>
      <h2>Mukhiyas List</h2>
      <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
        <Tree
          data={graphData}
          childrenAccessor="children"
          width="100%"
          height={1000} // Adjust height based on screen size
          indent={50} // Reduce indent for small screens
          rowHeight={50}
          padding={20}
        >
          {(nodeProps) => (
            <div style={{ position: 'relative' }}>
              {loadingNode === nodeProps.node.id && (
                <CircularProgress
                  size={18}
                  style={{ position: 'absolute', right: 10, top: '30%', transform: 'translateY(-50%)' }}
                />
              )}
              <NodeComponent
                {...nodeProps}
                handleNodeClick={(node) => handleNodeClick(node)}
                postMarkUnmarkFetch={(id) => fetchFamilyData(id)}
              />
            </div>
          )}
        </Tree>
      </div>
    </>
  );
};

export default MukhiyasListTreeView;
